/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_RZbOMT6AI",
    //"aws_user_pools_web_client_id": "6vsg19fkk2nko7sm581sdf8hda",
    "aws_user_pools_web_client_id": "3hnn9l4rugbujn5t6219fjrju9",
    "oauth": {},
    
    "aws_appsync_graphqlEndpoint": 'https://blhimz5jpfdfdjynn5jqolyt3e.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	"aws_appsync_region": 'ap-southeast-1',
	"aws_appsync_authenticationType": 'AWS_LAMBDA',
};

export default awsmobile;
