export default {

	//Login
	Account:	 'Cuenta',
	Password:	 'Contraseña',
	SignIn:	 'Iniciar Sesión',
	SignUp:	 'Registrar',

	//Event
	High:	 'ALTO',
	Medium:	 'MEDIO',
	Low:	 'BAJO',

	//Alert
	AlertHistory:	 'HISTORIA DE ALERTAS',
	SeeAll:	 'Ver Todo',

	//Live
	LiveCameras:	 'Cámaras',
	
	//Contact
	Contact:	 'CONTACTO',
	Add:	 'Añadir',
	
	//EventTitle
	ID:	 'ID',
	N:	 'N°',
	Emergency:	 'EMERGENCIA',
	
	//Map
	Map:	 'Mapa',
	
	//Note
	Notes:	 'Notas',
	Close_case:	 'ARCHIVAR EVENTO',
	
	//Player
	Channel:	 'CANAL',
	Playback:	 'REPRODUCCION',
	Live:	 'EN DIRECTO',
	
	//Popup
	Cancel:	 'Cancelar',
	Yes:	 'Sí',
	Customer:	 'Cliente',
	Event:	 'Evento',
	JumpTo:	 'ENTRAR',
	Close:	 'CERRAR',

	Auto_signout:	 'Cuenta regresiva de cierre de sesión automático:',
	time_1m:	 '1m',
	time_2m:	 '2m',
	time_3m:	 '3m',
	time_5m:	 '5m',
	time_10m:	 '10m',
	time_30m:	 '30m',
	time_1hr:	 '1hr',
	
	time_60s:	 '1m',
	time_120s:	 '2m',
	time_180s:	 '3m',
	time_300s:	 '5m',
	time_600s:	 '10m',
	time_1800s:	 '30m',
	time_3600s:	 '1hr',

	//SearchBar
	Search:	 'Buscar',
	Logout:	 'SALIR',
	Refresh:	 'Refresh',

	//temp
	GatewayName:	 'DIRECCION',

	//delete
	Delete_my_account:	 'Delete my account',
	Delete_account:	 'Delete account',
	Delete_account_text1:	 'Deleting your account will',
	Delete_account_text2:	 'Delete your account information and photos',
	Delete_account_text3:	 'Delete the account you shared with',
	Delete_account_text4:	 'Delete all videos in the cloud',
	Email_confirm:	 'Please enter your email address to confirm',
	Delete:	 'Delete',
	Email:	 'Email',
	Email_error:	 'Email error',

	Otp_confirm:	 'Please enter your OTP to confirm',

}	
	