import React, { useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import {IntlProvider} from 'react-intl'
import Auth from '@aws-amplify/auth';
import aws_exports from './aws-exports.js';

import {Helmet} from "react-helmet";

import Admin from "layouts/Admin.js";
//import Admin from "layouts/Admin_abus.js";

import en from './i18n/en.js';
import zh from './i18n/zh.js';
import es from './i18n/es.js';
import it from './i18n/it.js';

import Amplify from 'aws-amplify';
import Bus from './views/Dashboard/eventBus'
import constants from "views/Dashboard/GlobalData";

Amplify.configure(aws_exports)
//Auth.configure(aws_exports)

export default function Root() {
  const [locale, setlocale] = useState(navigator.language)
  console.log('locale',locale)
  
  let messages;
  // 根據使用者選擇的語系 locale 切換使用不同的 messages
  if (locale.includes('zh')) {
    messages = zh;
    constants.locale='zh';
  } else if (locale.includes('es')) {
    messages = es;
    constants.locale='es';
  } else if (locale.includes('it')) {
    messages = it;
    constants.locale='it';
  } else {
    messages = en;
    constants.locale='en';
  }

  const language_update = (language) => {
    constants.locale=language;
    setlocale(language)
  };

  const getSingleCacheData = async (cacheName, url) => {
    if (typeof caches === 'undefined') return false;
    
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    
    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
      //console.log('Fetched failed!')
      addDataIntoCache('MyLocale','https://localhost:3000',{locale:constants.locale});
    }
    else{
      return cachedResponse.json().then((item) => {
        //console.log('cacheStorage',item)
        constants.locale=item.locale;
        setlocale(item.locale);
      });
    }
  };

  const addDataIntoCache = async (cacheName, url, response) => {
    // Converting our respons into Actual Response form
    const data = new Response(JSON.stringify(response));
  
    if ('caches' in window) {
      var cache = await caches.open(cacheName)
      cache.put(url, data);
    }
  };

  useEffect(() => {
    getSingleCacheData('MyLocale','https://localhost:3000')

    //Bus.addListener('language_update', language_update);
    return(()=>{
      //Bus.removeListener('language_update', language_update);
    })
  }, []);

  return(
    <IntlProvider locale={locale} key={locale} defaultLocale="en" messages={messages}>
      <HashRouter>
        <Switch>
          <Route path="/" component={Admin} />
        </Switch>
      </HashRouter>
      <Helmet>
        <meta http-equiv="CACHE-CONTROL" content="no-cache" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </Helmet>
    </IntlProvider>
  )
}

ReactDOM.render(<Root />,document.getElementById("root"));