import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";

/*import Dashboard from "views/Dashboard/Dashboard.js";
import Videotest from "views/Videotest/Videotest.js";
import Debugmode from "views/Debugmode/Debugmode.js";
import GatewayList from "views/GatewayList/Home.js";
import Gateway from "views/GatewayList/Gateway.js";*/

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

const Dashboard = React.lazy(() => import("views/Dashboard/Dashboard.js"));
const Videotest = React.lazy(() => import("views/Videotest/Videotest.js"));
const Debugmode = React.lazy(() => import("views/Debugmode/Debugmode.js"));
const GatewayList = React.lazy(() => import("views/GatewayList/Home.js"));
const Idtoken_check = React.lazy(() => import("views/GatewayList/Idtoken_check.js"));
const Gateway = React.lazy(() => import("views/GatewayList/Gateway.js"));


let ps;

const routes = 
  []
  .concat(
    {
      path: "/dashboard",
      name: "dashboard",
      icon: "router",
      component: Dashboard,
      layout: "",
    }
  )
  .concat(
    {
      path: "/videotest",
      name: "videotest",
      icon: "router",
      component: Videotest,
      layout: "",
    }
  )
  .concat(
    {
      path: "/debugmode",
      name: "debugmode",
      icon: "router",
      component: Debugmode,
      layout: "",
    }
  )
  .concat(
    {
      path: "/GatewayList",
      name: "GatewayList",
      icon: "router",
      component: GatewayList,
      layout: "",
    }
  )
  .concat(
    {
      path: "/idtoken_check",
      name: "idtoken_check",
      icon: "router",
      component: Idtoken_check,
      layout: "",
    }
  )
  .concat(
    {
      path: "/Gateway",
      name: "Gateway",
      icon: "router",
      component: Gateway,
      layout: "",
    }
  )
  
const switchRoutes = (
  <React.Suspense fallback={<div></div>}>
  <Switch>
    {routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}
    <Redirect from="/" to="/GatewayList" />
  </Switch>
  </React.Suspense>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  
  const classes = useStyles();
  const mainPanel = React.createRef();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      </div>
    </div>
  );
}
