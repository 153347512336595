export default {

	//Login
	Account:	 '帳號',
	Password:	 '密碼',
	SignIn:	 '登入',
	SignUp:	 '註冊',

	//Event
	High:	 '高',
	Medium:	 '普通',
	Low:	 '低',

	//Alert
	AlertHistory:	 '警報記錄',
	SeeAll:	 'See All',

	//Live
	LiveCameras:	 '直播中錄影機',
	
	//Contact
	Contact:	 '連絡人',
	Add:	 'Add',
	
	//EventTitle
	ID:	 'ID',
	N:	 'N°',
	Emergency:	 '緊急情況',
	
	//Map
	Map:	 '地圖',
	
	//Note
	Notes:	 '筆記',
	Close_case:	 '關閉案子',
	
	//Player
	Channel:	 '頻道',
	Playback:	 '錄影',
	Live:	 '直播',
	
	//Popup
	Cancel:	 '取消',
	Yes:	 '確定',
	Customer:	 'Customer',
	Event:	 'Event',
	JumpTo:	 '跳轉至',
	Close:	 '關閉',

	Auto_signout:	 '自動登出倒數:',
	time_1m:	 '1m',
	time_2m:	 '2m',
	time_3m:	 '3m',
	time_5m:	 '5m',
	time_10m:	 '10m',
	time_30m:	 '30m',
	time_1hr:	 '1hr',
	
	time_60s:	 '1m',
	time_120s:	 '2m',
	time_180s:	 '3m',
	time_300s:	 '5m',
	time_600s:	 '10m',
	time_1800s:	 '30m',
	time_3600s:	 '1hr',

	//SearchBar
	Search:	 '搜尋',
	Logout:	 '登出',
	Refresh:	 '刷新',

	//temp
	GatewayName:	 '我的gateway',

	//delete
	Delete_my_account:	 '刪除我的帳號',
	Delete_account:	 '刪除帳號',
	Delete_account_text1:	 '刪除你的帳號將會',
	Delete_account_text2:	 '刪除你的帳號資料跟照片',
	Delete_account_text3:	 '刪除你分享的帳號',
	Delete_account_text4:	 '刪除所有在雲端的影片',
	Email_confirm:	 '請輸入信箱確認',
	Delete:	 '刪除',
	Email:	 'Email',
	Email_error:	 'Email error',

	Otp_confirm:	 'Please enter your OTP to confirm',

}	
	