const object = {
    
    event_array:[],
    nextToken:"start",
    alert_array:[
      {
        type:"PIR1",
        name:"PIR detector Motion trigger",
        time:1635494400,
        revised:true,
      },
      {
        type:"PIR2",
        name:"Magnetic cont.Door open",
        time:1636372800,
        revised:false,
      },
      {
        type:"PIR3",
        name:"Magnetic cont.Door open",
        time:1636383600,
        revised:false,
      },
      {
        type:"PIR3",
        name:"Magnetic cont.Door open",
        time:1635500000,
        revised:false,
      },
      {
        type:"PIR3",
        name:"Magnetic cont.Door open",
        time:1635500000,
        revised:false,
      },
    ],
    contact_array:[
      {
        name:"Mobie Clarke",
        identity:"Owner",
        phone1:"0922000111",
        phone2:"0922000112",
      },
      {
        name:"Ray Clayton",
        identity:"Brother",
        phone1:"0922000113",
        phone2:"0922000114",
      },
      {
        name:"Ray Clayton",
        identity:"Brother",
        phone1:"0922000113",
        phone2:"0922000114",
      },
      {
        name:"Ray Clayton",
        identity:"Brother",
        phone1:"0922000113",
        phone2:"0922000114",
      },
      {
        name:"Ray Clayton",
        identity:"Brother",
        phone1:"0922000113",
        phone2:"0922000114",
      },
      {
        name:"Ray Clayton",
        identity:"Brother",
        phone1:"0922000113",
        phone2:"0922000114",
      },
    ],
    installed_array:[
      {
        type:"PIR1",
        name:"Living Room PIR1",
        status:"Connected",
        last_alert:"5 min ago",
        partition:"Living Room",
        zone:1,
        feature_statue:"-",
      },
      {
        type:"PIR2",
        name:"Living Room PIR2",
        status:"Connected",
        last_alert:"5 min ago",
        partition:"Living Room",
        zone:1,
        feature_statue:"-",
      },
      {
        type:"PIR3",
        name:"Living Room PIR3",
        status:"Connected",
        last_alert:"5 min ago",
        partition:"Living Room",
        zone:1,
        feature_statue:"-",
      },
      {
        type:"PIR4",
        name:"Living Room PIR4",
        status:"Connected",
        last_alert:"5 min ago",
        partition:"Living Room",
        zone:1,
        feature_statue:"-",
      },
      {
        type:"PIR5",
        name:"Living Room PIR5",
        status:"Connected",
        last_alert:"5 min ago",
        partition:"Living Room",
        zone:1,
        feature_statue:"-",
      },
      {
        type:"PIR6",
        name:"Living Room PIR6",
        status:"Connected",
        last_alert:"5 min ago",
        partition:"Living Room",
        zone:1,
        feature_statue:"-",
      },
    ],
    lang:"en",
    lang_mapping:"en",
    langTable:null,
    eventdata:null,

    channelData:[{},{},{},{}],
    deviceData:[],
    ChannelSettinData:null,

    AWSData:null,
    idtoken:null,
    AWSSubscribe:null,
    LoginData:null,
    uid:'',
    locate:null,
    channel:0,
    get_event_times:0,

    resetAWS_DATA(){
        object.AWSData=null;
        object.AWSSubscribe=null;
        object.event_array=[];
        object.nextToken="start";
        object.lang_mapping="en";
        object.langTable=null;
    },

    resetGateway_DATA(){
        object.LoginData=null;
        object.uid='';
        object.locate=null;
        object.channel=0;
    },


    

};

export default object;