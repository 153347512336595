export default {

	//Login
	Account:	 'Account',
	Password:	 'Password',
	SignIn:	 'SignIn',
	SignUp:	 'SignUp',

	//Event
	High:	 'HIGH',
	Medium:	 'MEDIUM',
	Low:	 'LOW',

	//Alert
	AlertHistory:	 'ALERT HISTORY',
	SeeAll:	 'See All',

	//Live
	LiveCameras:	 'Live Cameras',
	
	//Contact
	Contact:	 'CONTACT',
	Add:	 'Add',
	
	//EventTitle
	ID:	 'ID',
	N:	 'N°',
	Emergency:	 'EMERGENCY',
	
	//Map
	Map:	 'Location',
	
	//Note
	Notes:	 'Notes',
	Close_case:	 'CLOSE CASE',
	
	//Player
	Channel:	 'CHANNEL',
	Playback:	 'Playback',
	Live:	 'Live',
	
	//Popup
	Cancel:	 'Cancel',
	Yes:	 'Yes',
	Customer:	 'Customer',
	Event:	 'Event',
	JumpTo:	 'JUMP TO',
	Close:	 'Close',

	Auto_signout:	 'Auto signout countdown:',
	time_1m:	 '1m',
	time_2m:	 '2m',
	time_3m:	 '3m',
	time_5m:	 '5m',
	time_10m:	 '10m',
	time_30m:	 '30m',
	time_1hr:	 '1hr',
	
	time_60s:	 '1m',
	time_120s:	 '2m',
	time_180s:	 '3m',
	time_300s:	 '5m',
	time_600s:	 '10m',
	time_1800s:	 '30m',
	time_3600s:	 '1hr',

	//SearchBar
	Search:	 'Search',
	Logout:	 'LOGOUT',
	Refresh:	 'Refresh',

	//temp
	GatewayName:	 'My house gateway',

	//delete
	Delete_my_account:	 'Delete my account',
	Delete_account:	 'Delete account',
	Delete_account_text1:	 'Deleting your account will',
	Delete_account_text2:	 'Delete your account information and photos',
	Delete_account_text3:	 'Delete the account you shared with',
	Delete_account_text4:	 'Delete all videos in the cloud',
	Email_confirm:	 'Please enter your email address to confirm',
	Delete:	 'Delete',
	Email:	 'Email',
	Email_error:	 'Email error',

	Otp_confirm:	 'Please enter your OTP to confirm',

}	
	